import React from 'react';
import { Document, Page as PDFPage, pdfjs } from 'react-pdf';

import {
  Container,
  Button,
  Grid,
  makeStyles
} from '@material-ui/core';

import Page from 'src/components/Page';
import {
  useLocation
} from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  navContainer: {
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main, color: '#fff', border: 'none', boxShadow: 'rgb(0 0 0 / 22%) 2px 3px 6px', textTransform: 'capitalize', fontWeight: 'normal', margin: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  pdfComponent: { width: '100%', overflow: 'scroll' },
}));

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PDFViewer = () => {
  let query = useQuery();
  const classes = useStyles();

  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + 'post_reports_view.php?report_id=' + query.get("rid"), {
      method: 'POST',
      body: localStorage.getItem('eToken')
    })
      .then(response => response.json())
      .then(data => {

      }).catch(function (error) {
        console.log(error);
      });
  }, []);

  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  return (
    <Page
      className={classes.root}
      title="Report"
    >
      <Container maxWidth="lg">
        <Grid container align='center' className={classes.navContainer}>
          <Grid item xs={3}>{pageNumber != 1 ? <Button onClick={() => setPageNumber(pageNumber - 1)} className={classes.buttonStyle}
            variant="outlined"> Prev</Button> : null}</Grid>
          <Grid item xs={6}><p margin={0}>
            Page {pageNumber} of {numPages}
          </p></Grid>
          <Grid item xs={3}> {pageNumber < numPages ? <Button onClick={() => setPageNumber(pageNumber + 1)} className={classes.buttonStyle}
            variant="outlined"> Next</Button> : null}</Grid>
        </Grid>




        <Document file={query.get("url")} onLoadSuccess={onDocumentLoadSuccess} className={classes.pdfComponent} renderMode="svg">
          <PDFPage pageNumber={pageNumber} renderMode="svg" />
        </Document>
      </Container>
    </Page >
  );
};

export default PDFViewer;
